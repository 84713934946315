import React, { useState, useEffect } from 'react';
import Axios from 'axios'; 
import './FooterPage.css';

const FooterPage = () => {  
  const [iPais, setCountry] = useState(null);
  const [iCiudad, setCity] = useState(null);

  useEffect(() => {
    Axios.post(`https://cloud.tupediste.com/process/location`)
      .then((response) => {
        if (response.data.reqip === false) {
          const country = '';
          const city = '';
          setCountry(country);
          setCity(city);
        } else {
          const { country, city } = response.data;
          setCountry(country);
          setCity(city);
        }
      })
      .catch((error) => {
        console.error('Ups, algo sucedio.');
      });
  }, []);

  return (
    <div className="iFooter"> 
      <div className="container"> 
        <div className="iFooter-column">
          <div className="iFooter-pie">
            <div className='iFooter-pie-l'>
              <span className="text-14-s tbg-withe">&nbsp;daringod&nbsp;&copy;&nbsp;2024</span>
            </div>
            <div className='iFooter-pie-r'>
              <span className="text-10-s tbg-withe">{iPais ? <span>{iPais}-{iCiudad}</span> : <span>Localizando...</span>}</span>
            </div> 
          </div>
        </div>
      </div>
    </div >
  );
};

export default FooterPage;
