import CompFooter from "../partscomp/footer/FooterPage";

function Footer() {
  return (
    <div>
      <CompFooter />
    </div>
  );
}

export default Footer;
